import * as React from "react"
import { useSelector, useDispatch } from "react-redux"

import "./styles.scss"

const Question = ({ blok, topicId, alternativeLabel, maximumCount=0 }) => {
    const topicAnswers = useSelector(state => state.topics[topicId].answers)
    const dispatch = useDispatch()

    const setOption = (questionId, topicId, value) => {
        dispatch({
            type: "SET_TOPIC_ANSWER",
            payload: {
                topic: topicId,
                questionId: questionId,
                answer: value
            }
        })


        dispatch({
            type: "SET_TOPIC_FINISHED",
            payload: {
                topic: topicId
            }
        })

        if (typeof topicAnswers[topicId] !== "undefined") {
            dispatch({
                type: "SET_TOPIC_PROGRESS",
                payload: {
                    topic: topicId,
                    completionRate: Object.keys(topicAnswers).length / maximumCount
                }
            })
        } else {
            dispatch({
                type: "SET_TOPIC_PROGRESS",
                payload: {
                    topic: topicId,
                    completionRate: (Object.keys(topicAnswers).length + 1) / maximumCount
                }
            })
        }
    }
    return (
        <div className="question">
            <div className="title">{blok.question}</div>
            <div className="options">
                <fieldset className="option">
                    { typeof topicAnswers[blok._uid] !== "undefined" && topicAnswers[blok._uid] === "1" ? <input type="radio" checked value={3} name={blok._uid} id={`yes_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} /> : <input type="radio" value={1} name={blok._uid} id={`yes_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} />}
                    <label for={`yes_${blok._uid}`}>Ja</label>
                </fieldset>
                <fieldset className="option">
                    { typeof topicAnswers[blok._uid] !== "undefined" && topicAnswers[blok._uid] === "2" ? <input type="radio" checked value={2} name={blok._uid} id={`no_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} /> : <input type="radio" value={2} name={blok._uid} id={`no_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} />}
                    <label for={`no_${blok._uid}`}>Nein</label>
                </fieldset>
                <fieldset className="option">
                    { typeof topicAnswers[blok._uid] !== "undefined" && topicAnswers[blok._uid] === "3" ? <input type="radio" checked value={3} name={blok._uid} id={`part_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} /> : <input type="radio" value={3} name={blok._uid} id={`part_${blok._uid}`} onChange={(event) => { setOption(blok._uid, topicId, event.target.value) }} />}
                    <label for={`part_${blok._uid}`}>{alternativeLabel}</label>
                </fieldset>
            </div>
        </div>
    )
}

export default Question