import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/button"
import Question from "../components/question"
import Seo from "../components/seo"

import "../styles/topic-pages.scss"

const TopicPage2 = (context) => {
  const pageContent = JSON.parse(context.pageContext.story.content)

  let maximumCount = 0
  pageContent.statusQuo.forEach(group => {
    maximumCount += group.questions.length
  })
  maximumCount += pageContent.whatDoWeNeed.length

  return (
    <Layout>
      <Seo title={context.pageContext.story.name} />
      <Link to="/" className="backButton">
        <div className="icon"></div>
        <div className="label">Zurück zur Übersicht</div>
      </Link>
      <div className="topic-page">
        <h1>{context.pageContext.story.name}</h1>
        <div className="full-page">
        <h2>Was brauche ich?</h2>
        <div className="what-do-we-need">
          {pageContent.whatDoWeNeed.map((question, i) =>
            <Question key={i} blok={question} topicId={pageContent.topicLabel} alternativeLabel="in Bearbeitung" maximumCount={maximumCount} />
          )}
        </div>
        <div className="actions">
          <Button type="secondary" to={`/${context.pageContext.story.full_slug}/analyse`} label="Zurück" />
          <Button type="primary" to={`/${context.pageContext.story.full_slug}/ressourcen`} label="Weiter" />
        </div>
        </div>
      </div>
    </Layout>
  )
}

export default TopicPage2
